@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;700;900&display=swap');

html {
    padding: 0;
    height: 100%;
    width: 100%;
}
body {
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Barlow',Arial, Helvetica, sans-serif !important;
    font-size: 12px;
    background-color: rgb(255, 255, 255) !important;
}

.container {
    font-family: 'Barlow',Arial, Helvetica, sans-serif;
    font-size: 1em;
    background-color: transparent;
}

.Header {
    height: 100px;
    width: 100%;
    background: rgba(0, 0,0,.7);
    font-family: 'Barlow Condensed';
    color: rgba(255,255,255,1);
    transition: height ease-out .3s, background-color ease-out .3s;
    position: fixed;
    top: 0;
    left: 0;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    z-index: 100;
}

#root {
    height: 100%;
}

.Footer {
    width: 100%;
    background: rgb(69, 69, 69);
    color: rgb(187, 186, 186);
    font-size: 10pt;
    padding-top: 20px;
    padding-bottom: 20px;
}

.HeaderShrink {
    height: 50px;
    width: 100%;
    background-color: rgba(0,0,0,1);
    font-family: 'Barlow Condensed';
    color: rgba(255,255,255,1);
    position: fixed;
    top: 0;
    left: 0;
    transition: height ease-out .3s, background-color ease-out .3s;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    z-index: 100;
}

.contentTop {
    margin-top: 110px;
}

    .contentTop:has(.homeCarousel) {
        margin-top: 0px;
    }


    .homeCarousel {
        /* position: absolute;
    top: 0;
    left: 0;*/
        width: 100%;
        min-height: 400px;
        /*background-color: rgb(0,0,0);*/
        z-index: 1;
    }

.carouselImage {
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    filter: blur(4px) brightness(50%) grayscale(.2);
}

.carousel-item {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.carousel-inner {
    display: flex;
    align-items: center !important;
}


.headerMenuItem {
    font-family: 'Barlow';
    font-weight: 500;
    color: rgb(216, 216, 216);
    font-size: 8pt;
   /* border-left: 1px solid rgba(255,255,255,.3);*/
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 25px;
    border-bottom: none;
    position: relative;
    cursor: pointer;
}

    .headerMenuItem::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: rgb(119, 72, 224);
        transform: scaleX(0);
        /*transform-origin: top right;*/
        transition: transform 0.5s ease-out;
    }

    .headerMenuItem:hover::before {
        transform: scaleX(1);
       /* transform-origin: top left;*/
    }

    .headerMenuItem a {
        color: rgb(216, 216, 216);
        text-decoration: none;
        
    }

.profilePictureHeader {
   /* min-width: 50px;*/
    width: 100%;
    border-radius: 50%;
    border: 3px solid #ffffff;
}

.profileHeaderText {
    font-family: 'Barlow Condensed';
    font-size: 12pt;
    font-weight: 700;
    text-decoration: underline;
    /*text-decoration-color: rgb(119, 72, 224);*/
}

.profileHeaderSignout{
    font-family: 'Barlow Condensed';
    font-size: 12pt;
    font-weight: 500;
}

.blackout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 9000;
}

.loginPopupDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9001;
}

.loginPopup {
    height: 80%;
    width: 80%;
    background-color: rgb(225, 225, 225);
    border-radius: 10px;
    max-height: 550px;
    max-width: 400px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.registerPage {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.registerDiv {
   
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}

.sectionbox {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
}




.divCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.text-divider {
    display: flex;
    align-items: center;
    color: #6d6d6d;
    font-size: 14px;
    line-height: 19px;
    margin: 16px 0;
}

    .text-divider::before, .text-divider::after {
        content: "";
        height: 1px;
        background-color: #d1d0d0;
        flex-grow: 1;
    }

    .text-divider::before, .text-divider:after {
        margin: 10px;
    }

.errorPopup {
    width: 80%;
    background-color: rgb(225, 225, 225);
    border-radius: 10px;
    max-height: 550px;
    max-width: 400px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.closeButton{
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    cursor: pointer;
}


.fontLogo1 {
    font-family: 'Barlow Condensed';
    font-weight: 500;
    color: #ffffff;
    font-size: 18px;
    line-height: 18px;
}

.fontLogo2 {
    font-family: 'Barlow Condensed';
    font-weight: 700;
    color: #ffffff;
    font-size: 24px;
    line-height: 24px;
}

.fontBanner3 {
    font-family: 'Barlow Condensed';
    font-weight: 700;
    color: rgb(255, 101, 0);
    font-size: 180px;
    
}

.fontBanner1 {
    font-family: 'Barlow Condensed';
    font-weight: 500;
    color: #ffffff;
    font-size: 28px;
    line-height: 28px;
}

.fontBanner2 {
    font-family: 'Barlow Condensed';
    font-weight: 700;
    color: #ffffff;
    font-size: 34px;
    line-height: 34px;
}

.fontLogin {
    font-family: 'Barlow';
    font-weight: 500;
    color: rgb(216,216,216);
    font-size: 10pt;
}

.fontNormal {
    font-family: 'Barlow';
    font-weight: normal;
    font-size: 12pt;
}

.fontRegister {
    font-family: 'Barlow';
    font-weight: 500;
    color: rgb(216,216,216);
    font-size: 9pt;
}

.Footer a {
    text-decoration: none;
    color: rgb(187, 186, 186);
}

.appBadge {
    width: 135px;
    height: 40px;
}

h1,h2,h3,h4 {
    font-family: 'Barlow';
}

section {
    min-height: 400px;
    top: -120px;
    position: relative;
    z-index: 10;
    color: rgb(30, 30, 30);
}

    section.main {
        background-color: rgb(38, 24, 69);
        /*min-height: 480px;*/
        position: relative;
        top: -120px;
        padding-top: 120px;
        margin: 0 auto;
        color: #ffffff;
    }

    section.product {
        background-color: rgb(255, 255, 255);
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0 auto;
    }

    section.product1{
        background-color: rgb(178, 109, 0);
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0 auto;
    }

    section.testimolials{
        background-color: rgb(255,255,255);
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0 auto;
        top: -60px !important;
    }

    .quote{
        font-family: 'Barlow';
        font-size: 18pt;
        font-weight: 600;
        font-style: italic;

    }

.testimonialsImg {
    border-radius: 50%;
    border: 15px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.9) 4px 4px 8px 0px;
}

/*.roundIndicators {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #000;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.roundIndicators.active {
    opacity: 1;
}*/

.elips {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.rowitem {
    margin-top: 2px;
    margin-bottom: 2px;
    border-bottom: 1px solid rgba(69, 69, 69,.3);
}
a {
    text-decoration: none !important;
}

.carousel-indicators button {
    width: 0.4rem !important;
    height: 0.4rem !important;
    border-radius: 50%;
    background-color: rgb(119, 72, 224) !important;
}

    .carousel-indicators button:not(:first-child) {
        margin-left: 0.5rem;
    }

.carousel-indicators .active {
    background-color: rgb(119, 72, 224) !important;
}



.delay3s {
    animation-delay: 3s /* W3C and Opera */;
    -moz-animation-delay: 3s /* Firefox */;
    -webkit-animation-delay: 3s /* Safari and Chrome */;
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    -moz-animation-name: bounceInUp;
    -o-animation-name: bounceInUp;
    animation-name: bounceInUp;
}

.animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
}

.statsBox {
    position: fixed;
    background-color: rgba(0,0,0,.7);
    color: #ffffff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
    top: 110px;
    right: 10px;
}

.planeLabel {
    background-color: rgba(0,0,0,.5);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 24pt;
    font-family: 'Barlow Condensed'
}

.planeLabelClimbing {
    background-color: rgba(99,99,0,.5);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 24pt;
    font-family: 'Barlow Condensed'
}

.planeLabelCruise {
    background-color: rgba(0,0,0,.5);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 24pt;
    font-family: 'Barlow Condensed'
}

.planeLabelDescending {
    background-color: rgba(0,99,99,.5);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 24pt;
    font-family: 'Barlow Condensed'
}

.normal {
    font-weight: 400;
}

.bold {
    font-weight: 700;
}

.black {
    font-weight: 900;
}

.medium {
    font-weight: 500;
}

.light {
    font-weight: 300;
}

.fontLabel{
    font-size: 9pt;
    font-family: 'Barlow Condensed';
    color: #666;
}

.fontLabelData{
    font-size: 14pt;
    font-family: 'Barlow';
    color: #333;
    font-weight: bold;
}

.aircraftLogCallSign {
    font-family: 'Barlow';
    font-size: 24pt;
    
    font-weight: 700;
    /*text-shadow: 1px 1px 3px rgba(0,0,0,.9);*/
}

.emailRow:nth-child(2n+1) {
    height: 50px;
    background-color: #efefef;
    border-bottom: 1px dotted #666666;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.emailRow {
    height: 50px;
    border-bottom: 1px dotted #666666;
    display: flex;
    align-items: center;
    cursor: pointer;
}

    .emailRow:nth-child(2n+1):hover {
        height: 50px;
        background-color: #333333;
        color: #ffffff;
        border-bottom: 1px dotted #666666;
        display: flex;
        align-items: center;
        cursor: pointer;

    }

    .emailRow:hover {
        height: 50px;
        background-color: #333333;
        color: #ffffff;
        border-bottom: 1px dotted #666666;
        display: flex;
        align-items: center;
        cursor: pointer;
    }


